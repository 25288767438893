module.exports = [{
      plugin: require('../../../node_modules/@whitespace/gatsby-plugin-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@whitespace/gatsby-plugin-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"sv","languages":["sv","en"]},
    },{
      plugin: require('../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/falkenberg.devspace.se/projects/falkenberg.se","fragmentsDir":"/var/lib/jenkins/workspace/falkenberg.devspace.se/projects/falkenberg.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.falkenberg.devspace.se","nodesPerFetch":150,"contentTypes":{"page":{},"event":{}},"taxonomies":{"eventCategory":{},"eventTag":{}}},"search":{"paths":["sok","en/search"]},"postCss":{"postcssOptions":{"plugins":[null]}},"compileES6Packages":{"modules":["@whitespace/components"]}},
    },{
      plugin: require('../../../node_modules/@municipio/gatsby-theme-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/falkenberg.devspace.se/projects/falkenberg.se","fragmentsDir":"/var/lib/jenkins/workspace/falkenberg.devspace.se/projects/falkenberg.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.falkenberg.devspace.se","nodesPerFetch":150,"contentTypes":{"page":{},"event":{}},"taxonomies":{"eventCategory":{},"eventTag":{}}},"search":{"paths":["sok","en/search"]},"postCss":{"postcssOptions":{"plugins":[null]}},"compileES6Packages":{"modules":["@whitespace/components"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Destination Falkenberg","short_name":"Falkenberg","start_url":"/","background_color":"#fff","theme_color":"#DDAACC","display":"standalone","icon":"src/images/icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"91c12cb11c29a7c9d5bea4b61baff6fe"},
    },{
      plugin: require('../../../node_modules/@whitespace/gatsby-plugin-cookie-consent/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://falkenberg.devspace.se","stripQueryString":true},
    },{
      plugin: require('../../../packages/gatsby-theme-falkenberg/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/falkenberg.devspace.se/projects/falkenberg.se","fragmentsDir":"/var/lib/jenkins/workspace/falkenberg.devspace.se/projects/falkenberg.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv","en"]},"wp":{"url":"https://cms.falkenberg.devspace.se","nodesPerFetch":150,"contentTypes":{"page":{},"event":{}},"taxonomies":{"eventCategory":{},"eventTag":{}}},"search":{"paths":["sok","en/search"]},"postCss":{"postcssOptions":{"plugins":[null]}},"compileES6Packages":{"modules":["@whitespace/components"]}},
    }]
