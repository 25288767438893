import * as Yup from "yup";

export const formFields = {
  title: {
    hidden: false,
    name: "title",
    label: "Namn på evenemang",
    hidden_description: false,
    // description: "Namn på evenemang.",
    required: true,
  },
  content: {
    hidden: false,
    name: "content",
    id: "content textarea",
    label: "Beskrivning",
    required: true,
    hidden_description: false,
    description:
      "Beskriv ditt evenemang. Vad händer där och varför ska man besöka det?  Skriv det viktigaste först. Använd ord som du tror att läsarna förstår. Skriv kort och koncist.",
  },
  useRcrRules: {
    hidden: false,
    name: "useRcrRules",
    label: "Evenemanget inträffar",
    hidden_description: false,
    required: true,
    description:
      "Lägg till tidpunkter för evenemanget. Återkommer evenemanget varje vecka? Lägg då till ett schema för återkommande evenemang. Observera att sluttiden för evenemanget inte kan vara samma som starttiden.",
    options: [
      { value: "", label: "Enstaka tillfällen" },
      { value: "yes", label: "Återkommande tillfällen" },
    ],
  },
  // "single-event": {
  //   hidden: false,
  //   name: "single-event",
  //   label: "Återkommande evenemang",
  //   hidden_description: true,
  //   // description: " ",
  //   required: true,
  // },
  // "recurring-event": {
  //   hidden: false,
  //   name: "reccuring-event",
  //   label: "Schemalagda evenemang",
  //   hidden_description: true,
  //   // description: " ",
  //   required: true,
  // },
  event_link: {
    hidden: false,
    name: "event_link",
    id: "event_link",
    label: "Hemsida",
    required: false,
    inputProps: {
      placeholder: "www.exempel.se",
    },
    hidden_description: false,
    description: "Länk till evenemangets webbplats.",
  },
  booking_link: {
    hidden: false,
    name: "booking_link",
    id: "booking_link",
    label: "Länk till bokning",
    required: false,
    inputProps: {
      placeholder: "www.exempel.se",
    },
    hidden_description: false,
    description:
      "Länk till evenemangets bokningssida, exempelvis Ticketmaster.",
  },
  price_adult: {
    hidden: false,
    name: "price_adult",
    id: "price_adult",
    label: "Pris för vuxna",
    required: false,
    hidden_description: false,
    description:
      "För vuxen. Finns det flera olika prisklasser? Informera gärna om det i beskrivningen.",
  },
  price_student: {
    hidden: false,
    name: "price_student",
    id: "price_student",
    label: "Pris för studenter",
    required: false,
    hidden_description: false,
    description:
      "För studenter. Finns det flera olika prisklasser? Informera gärna om det i beskrivningen.",
  },
  price_children: {
    hidden: false,
    name: "price_children",
    id: "price_children",
    label: "Pris för barn",
    required: false,
    hidden_description: false,
    description:
      "För barn. Finns det flera olika prisklasser? Informera gärna om det i beskrivningen.",
  },
  children_age: {
    hidden: false,
    name: "children_age",
    id: "children_age",
    label: "Ålder för barnpris",
    required: false,
    hidden_description: false,
    description: " ",
  },
  price_senior: {
    hidden: false,
    name: "price_senior",
    id: "price_senior",
    label: "Pris för seniorer",
    required: false,
    hidden_description: false,
    description:
      "För seniorer. Finns det flera olika prisklasser? Informera gärna om det i beskrivningen.",
  },
  senior_age: {
    hidden: false,
    name: "senior_age",
    id: "senior_age",
    label: "Ålder för seniorpris",
    required: false,
    hidden_description: false,
    description: " ",
  },
  age_group: {
    hidden: false,
    name: "age_group",
    label: "Åldersgrupp",
    required: false,
    hidden_description: false,
    description: "Åldersgrupp som aktiviteten är riktad till.",
    fields: [
      {
        hidden: false,
        name: "age_group_from",
        id: "age_group_from",
        label: "Från",

        hidden_description: false,
        description: " ",
        width: "50%",
      },
      {
        hidden: false,
        name: "age_group_to",
        id: "age_group_to",
        label: "Till",

        hidden_description: false,
        description: " ",
        width: "50%",
      },
    ],
  },
  "organizer-selector": {
    hidden: false,
    name: "organizer-selector",
    id: "organizer-selector",
    label: "Arrangör",
    hidden_description: false,
    description:
      "Skriv arrangörens namn och välj från de förslag som vi ger dig. Dyker inte arrangörens namn upp, kan du nämna det i din beskrivning.",
    options: [
      {
        value: "existing",
        label: "Befintlig arrangör",
      },
      { value: "new", label: "Ny arrangör" },
    ],
  },
  organizer: {
    hidden: false,
    name: "organizer",
    id: "organizer",
    label: "Välj arrangör",
    hidden_description: false,
    description: " ",
    apiProps: {
      type: "posttype",
      label: "organizer",
    },
    defaultFieldObject: {
      "organizer-name": "",
      "organizer-phone": "",
      "organizer-mail": "",
    },
    fields: [
      {
        hidden: false,
        name: "organizer-name",
        id: "organizer-name",
        label: "Namn",

        hidden_description: false,
        description: " ",
        width: "100%",
      },
      {
        hidden: false,
        name: "organizer-phone",
        id: "organizer-phone",
        label: "Telefonnummer",

        hidden_description: false,
        description: " ",
        width: "100%",
      },
      {
        hidden: false,
        name: "organizer-mail",
        id: "organizer-mail",
        label: "E-postadress",

        hidden_description: false,
        description: " ",
        width: "100%",
      },
    ],
  },
  "location-selector": {
    hidden: false,
    name: "location-selector",
    id: "location-selector",
    label: "Plats",
    hidden_description: false,
    description:
      "Skriv platsens namn och välj från de förslag som vi ger dig. Dyker inte platsens namn upp, kan du nämna det i din beskrivning.",
    options: [
      {
        value: "existing",
        label: "Befintlig plats",
      },
      { value: "new", label: "Ny plats" },
    ],
  },
  location: {
    hidden: false,
    name: "location",
    id: "location",
    label: "Välj plats",
    hidden_description: false,
    description: " ",
    apiProps: {
      type: "posttype",
      label: "location",
    },
    defaultFieldObject: {
      "location-title": "",
      "location-street-address": "",
      "location-postal-code": "",
      "location-city": "",
    },
    fields: [
      {
        hidden: false,
        name: "location-title",
        id: "location-title",
        label: "Namn",

        hidden_description: false,
        description: " ",
        width: "100%",
      },
      {
        hidden: false,
        name: "location-street-address",
        id: "location-street-address",
        label: "Gatuadress",

        hidden_description: false,
        description: " ",
        width: "100%",
      },
      {
        hidden: false,
        name: "location-postal-code",
        id: "location-postal-code",
        label: "Postnummer",

        hidden_description: false,
        description: " ",
        width: "100%",
      },
      {
        hidden: false,
        name: "location-city",
        id: "location-city",
        label: "Ort",

        hidden_description: false,
        description: " ",
        width: "100%",
      },
    ],
  },
  accessibility: {
    hidden: false,
    name: "accessibility",
    id: "accessibility",
    label: "Tillgänglighet",
    required: false,
    hidden_description: false,
    description: "Välj vilka tillgänglighetsåtgärder som finns för platsen.",
    options: [
      { value: "elevator_ ramp", label: "Hiss/ramp" },
      { value: "accessible_toilet", label: "Handikapptoalett" },
    ],
  },
  categories: {
    hidden: false,
    name: "categories",
    id: "categories",
    label: "Kategorier",
    required: false,
    fieldProps: {
      isMulti: true,
      isSearchable: true,
    },
    hidden_description: false,
    description: "Välj passande kategorier för ditt evenemang eller aktivitet.",
    apiProps: {
      type: "taxonomy",
      label: "event_categories",
    },
  },
  tags: {
    hidden: false,
    name: "tags",
    id: "tags",
    label: "Taggar",
    required: false,
    fieldProps: {
      isMulti: true,
      isSearchable: true,
    },
    hidden_description: false,
    description:
      "Välj passande taggar för ditt evenemang eller aktivitet. För att välja flera kategorier håll in Ctrl (Windows) / command (macOS) samtidigt som du klickar på respektive kategori.",
    apiProps: {
      type: "taxonomy",
      label: "event_tags",
    },
  },
  // image: {
  //   hidden: false,
  //   name: "image",
  //   id: "image",
  //   label: "Ladda up en bild",
  //   required: false,
  //   fieldProps: {
  //     accept: "image/gif, image/jpeg, image/png",
  //   },
  //   hidden_description: false,
  //   description:
  //     "Tänk på att bilden kan bli beskuren, undvik därför text i bilden. Bilder med igenkännbara personer godkänns inte och kommer ersättas. Du måste även ha rätt att använda och sprida bilden.",
  // },
  email: {
    hidden: false,
    name: "submitter_email",
    id: "submitter_email",
    label: "E-postadress",
    required: true,
    // inputProps: {
    //   placeholder: "namn@namnsson.se",
    // },
    hidden_description: false,
    description: " ",
  },
  email_repeat: {
    hidden: false,
    name: "submitter_email_repeat",
    id: "submitter_email_repeat",
    label: "Upprepa e-postadress",
    required: true,
    // inputProps: {
    //   placeholder: "namn@namnsson.se",
    // },
    hidden_description: false,
    // description:
    //   "E-postadressen måste matcha med det ifyllda adressen i förra fältet.",
  },
  phone: {
    hidden: false,
    name: "submitter_phone",
    id: "submitter_phone",
    label: "Telefonnummer",
    required: true,
    // inputProps: {
    //   placeholder: "123456789",
    // },
    hidden_description: false,
    // description: "Ditt telefonnummer.",
  },
};

export const FormFieldsSchema = Yup.object({
  title: Yup.string().required("Fältet är obligatoriskt!"),
  content: Yup.string().required("Fältet är obligatoriskt!"),
  occasions: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.string(),
      start_time: Yup.string(),
      end_date: Yup.string(),
      end_time: Yup.string(),
    }),
  ),
  rcr_rules: Yup.array().of(
    Yup.object().shape({
      rcr_week_day: Yup.string(),
      rcr_weekly_interval: Yup.number(),
      rcr_start_time: Yup.string(),
      rcr_end_time: Yup.string(),
      rcr_start_date: Yup.string(),
      rcr_end_date: Yup.string(),
    }),
  ),
  event_link: Yup.string(),
  booking_link: Yup.string(),
  price_adult: Yup.number(),
  price_student: Yup.number(),
  price_children: Yup.number(),
  children_age: Yup.number(),
  price_senior: Yup.number(),
  senior_age: Yup.number(),
  age_group_from: Yup.number(),
  age_group_to: Yup.number(),
  organizer: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
    phone: Yup.string(),
    mail: Yup.string(),
  }),
  location: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
    street_address: Yup.string(),
    postal_code: Yup.string(),
    city: Yup.string(),
  }),
  accessibility: Yup.array(),
  event_categories: Yup.array().of(Yup.string()),
  // tags: Yup.array().of(Yup.string()),
  // image: {},
  submitter_email: Yup.string()
    .email("Fyll i en korrekt e-postadress")
    .required("Fältet är obligatoriskt"),
  submitter_email_repeat: Yup.string()
    .email("Fyll i en korrekt e-postadress")
    .required("Fältet är obligatoriskt")
    .oneOf([Yup.ref("submitter_email")], "E-postadresserna måste matcha"),
  submitter_phone: Yup.string().required("Fältet är obligatoriskt"),
});
