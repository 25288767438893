// extracted by mini-css-extract-plugin
export var form = "EventSubmitModule-module--form--kZGjs";
export var field = "EventSubmitModule-module--field--4olUp";
export var required = "EventSubmitModule-module--required--Hy3Mv";
export var radio = "EventSubmitModule-module--radio--gonDx";
export var fieldGroupFieldset = "EventSubmitModule-module--fieldGroupFieldset--7n8w5";
export var fieldLabel = "EventSubmitModule-module--fieldLabel--RSk67";
export var fieldDescription = "EventSubmitModule-module--fieldDescription--ubbfb";
export var fieldWrapper = "EventSubmitModule-module--fieldWrapper--Yz4ot";
export var fieldGroup = "EventSubmitModule-module--fieldGroup--CPK89";
export var fieldSubmit = "EventSubmitModule-module--fieldSubmit--xj84h";